import React, { useEffect } from 'react';
import HomeDefault from 'src/front/views/HomeDefault';
import HomeLanding from 'src/front/views/HomeLanding';
import { IS_SERVER } from 'src/constants';
import useLang from 'src/hooks/useLang';
import { useHistory } from 'react-router';
import Helper from 'src/front/helpers/Helper';
import { useSelector } from 'react-redux';
import microdataGenerator from 'src/utils/microdata';

function Home(props) {
  const lang = useLang('Home');
  const history = useHistory();
  const { language: { lang: language, languages, localization } } = useSelector((state) => state);

  useEffect(() => {
    microdataGenerator({
      name: 'WebSite',
      language: languages.filter((l) => l._id === language)[0],
      localization,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //if (props.location.pathname !== '/' && props.location.pathname.endsWith('/')) history.replace({ pathname: props.location.pathname.replace(/\/+$/, ''), search: props.location.search, state: { isActive: true }});

  return (
    <>
      {
        (IS_SERVER || lang.currentLocalization.mapType === 'landing') &&
        <>
          {IS_SERVER && "{if (staticData.language.localizations[localization].mapType == 'landing') :}"}
            <HomeLanding {...props}/>
          {IS_SERVER && '{endif}'}
        </>
      }
      {
        (IS_SERVER || lang.currentLocalization.mapType !== 'landing') &&
        <>
          {IS_SERVER && "{if (staticData.language.localizations[localization].mapType != 'landing') :}"}
            <HomeDefault {...props}/>
          {IS_SERVER && '{endif}'}
        </>
      }
    </>
  );
}

export default Home;
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { IS_SERVER } from 'src/constants';
import Helper from 'src/front/helpers/Helper';
import { useHistory } from 'react-router';
import $ from 'jquery';

export default ({
    country,
    regionsSettings = [],
    cities = [],
    useMapData,
    strokeColor = '#fff',
    fillColor = '#000',
    hoverColor = '#fff',
    popupBackground = 'rgba(255, 255, 255, 0.5)',
    popupTextColor = '#000',
    popupBorderColor = '#fff',
    style = {},
    offset = { left: 0, right: 0, top: 0, bottom: 0 },
    citiesSize = 0,
    ...rest
  }) => {
  const history = useHistory();
  const firstRendering = useRef(true);
  const [firsRenderState, setFirsRenderState] = useState(true);
  const [mapData, setMapData] = useState(!IS_SERVER && firstRendering.current && rest.id ? (() => {
    const element = document.getElementById(rest.id);

    if (element) {
      const data = { attributes: {}, regions: [] };
      const regions = element.getElementsByTagName('path');

      for (let i = 0; i < element.attributes.length; i++) {
        if (!['style', 'viewBox'].includes(element.attributes[i].nodeName)) data.attributes[element.attributes[i].nodeName] = element.attributes[i].nodeValue;
      }

      for (let i = 0; i < regions.length; i++) {
        const attributes = {};

        for (let j = 0; j < regions[i].attributes.length; j++) {
          if (!['class', 'style', 'data-list-position', 'fill', 'stroke'].includes(regions[i].attributes[j].nodeName)) {
            attributes[regions[i].attributes[j].nodeName] = regions[i].attributes[j].nodeValue;
          }
        }

        data.regions.push(attributes);
      }

      return data;
    }

    return null;
  })() : null);
  const [hoverElement, setHoverElement] = useState(null);
  const [regionTitle, setRegionTitle] = useState(null);
  const wrapperRef = useRef(null);
  const defaultScale = mapData?.attributes?.width ? mapData.attributes.width / 656 : 1
  const scale = defaultScale + defaultScale / 100 * citiesSize;
  const defaultCityPosition = { x: 52, y: 22 };
  const sizes = {
    rectWidth: {
      2: 30,
      3: 30,
      4: 37,
      5: 45,
    },
    tempPosition: {
      2: 40,
      3: 36,
      4: 35,
      5: 35,
    }
  };

  firstRendering.current = false;

  if (offset?.constructor === Object) {
    // eslint-disable-next-line no-unused-vars
    for (const k in offset) if (!Helper.isNumeric(offset[k])) offset[k] = 0;
  }

  const checkCoordinateValue = (v, c) => {
    const min = { x: 52, y: 22 };
    const max = { x: mapData.attributes.width, y: mapData.attributes.height };

    if (!Helper.isNumeric(v) || v > max[c] || v < 0) return min[c];

    return v;
  };

  useEffect(() => {
    axios
      .get(`/get-svg-map/${encodeURIComponent(country)}`, { withCredentials: true })
      .then(({ data }) => setMapData(data));
  }, [country]);

  useEffect(() => {
    if (mapData && useMapData) useMapData({ regions: mapData.regions, map: wrapperRef.current });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapData]);

  useEffect(() => {
    setFirsRenderState(false);
  }, []);

  if (!IS_SERVER && !mapData) return null;

  return (
    IS_SERVER ?
    <div style={{ position: 'relative' }}>
      {`{exec}
        dynamicValue = JSON.parse(fs.readFileSync(path.resolve(APP_DIR, 'svg_maps/' + ${country} + '.json')).toString());

        const regionsSettings = ${regionsSettings};
        const cities = ${cities};
        const checkCoordinateValue = (v, c) => {
          const min = { x: 52, y: 22 };
          const max = { x: dynamicValue.attributes.width, y: dynamicValue.attributes.height };
      
          if (!Helper.isNumeric(v) || v > max[c] || v < 0) return min[c];
      
          return v;
        };
        const defaultScale = dynamicValue.attributes.width ? dynamicValue.attributes.width / 656 : 1;
        const scale = defaultScale + defaultScale / 100 * ${citiesSize};
        const sizes = ${Helper.objectToString(sizes)};
        const rest = ${Helper.objectToString(rest)};
        const offset = ${typeof offset == 'string' ? offset : Helper.objectToString(offset)};
        const style = ${typeof style == 'string' ? style : Helper.objectToString(style)};

        if (offset && offset.constructor == Object) {
          for (const k in offset) if (!Helper.isNumeric(offset[k])) offset[k] = 0;
        }

        for (const k in rest) dynamicValue.attributes[k] = rest[k];
        dynamicValue.attributes.viewBox = offset.left * -1 + ' ' + offset.top * -1 + ' ' + (parseFloat(dynamicValue.attributes.width) + parseFloat(offset.left) + parseFloat(offset.right)) + ' ' + (parseFloat(dynamicValue.attributes.height) + parseFloat(offset.top) + parseFloat(offset.bottom));
      {/exec}`}
      {`
        {literal}
          <svg style="{print Object.keys(style).map((k) => k + ':' + style[k] + ';').join('')}width:100%;height:auto" {print Object.keys(dynamicValue.attributes).map((a) => a + '="' + dynamicValue.attributes[a] + '"').join(' ')}>
            {for (let i = 0; i < dynamicValue.regions.length; i++) :}
              <path
                class="{print regionsSettings[i] && regionsSettings[i].svgMapClasses ? regionsSettings[i].svgMapClasses.join(' ') : ''}"
                style="{print regionsSettings[i] && regionsSettings[i].style ? Object.keys(regionsSettings[i].style).map((s) => s + ':' + regionsSettings[i].style[s]).join(';') : ''}"
                data-list-position="{print i}"
                fill="{print regionsSettings[i] && regionsSettings[i].color ? regionsSettings[i].color : '${fillColor}'}"
                stroke="{print ${strokeColor}}"
                {print Object.keys(dynamicValue.regions[i]).map((a) => a + '="' + dynamicValue.regions[i][a] + '"').join(' ')}
              />
            {endfor}
            {for (let i = 0; i < cities.length; i++) :}
              <g
                class="{print cities[i].svgMapClasses ? cities[i].svgMapClasses.join(' ') : ''}"
                transform="{print 'translate(' + checkCoordinateValue(cities[i].x, 'x') + ', ' + checkCoordinateValue(cities[i].y, 'y') + ') scale(' + scale + ') translate(-${defaultCityPosition.x}, -${defaultCityPosition.y})'}"
                style="{print cities[i].style ? Object.keys(cities[i].style).map((s) => s + ':' + cities[i].style[s]).join(';') : ''}"
                data-list-position="{print i}"
              >
                <image x="0" y="0" href="{print Helper.getFileUrl('weather-icon', cities[i].weather.icon + '.svg')}" width="28" height="28"/>
                <rect x="30" y="4" rx="10" ry="10" width="{print sizes.rectWidth[cities[i].weather.temp.length]}" height="20" style="filter:drop-shadow(rgb(0, 167, 227) 1px 1px 6px);fill:rgba(237, 247, 253)" />
                <text x="{print sizes.tempPosition[cities[i].weather.temp.length] + (cities[i].weather.temp.substring(0, 1) == '-' ? 2 : 0)}" y="18" style="fill:#01488f;font-size:13px;font-family:Roboto">{print cities[i].weather.temp}</text>
                <text x="{print sizes.rectWidth[cities[i].weather.temp.length] + 35}" y="19" style="font-size:15px;fill:#1f5892;font-family:Roboto;text-shadow:-2px -2px 0px #fff, 0px -2px 0px #fff, 2px -2px 0px #fff, 2px 0px 0px #fff, 2px 2px 0px #fff, 0px 2px 0px #fff, -2px 2px 0px #fff, -2px 0px 0px #fff">{print cities[i].name}</text>
              </g>
            {endfor}
          </svg>
        {/literal}
      `.replace(/(<[a-z]+)\s{2,}/g, '$1 ').replace(/\s{2,}/g, '')}
    </div> :
    <div
      style={{ position: 'relative' }}
      ref={wrapperRef}
      onMouseMove={(ev) => {
        if (hoverElement !== null && regionsSettings[hoverElement] && regionsSettings[hoverElement].name) {
          const rect = wrapperRef.current.getBoundingClientRect();
          setRegionTitle({ x: ev.clientX - rect.x, y: ev.clientY - rect.y, name: regionsSettings[hoverElement].name });
        } else setRegionTitle(null);
      }}
      onMouseLeave={() => setRegionTitle(null)}
    >
      {
        regionTitle &&
        <div style={{
          position: 'absolute', top: regionTitle.y + 'px', left: regionTitle.x + 'px', background: popupBackground, color: popupTextColor, fontFamily: 'Roboto',
          padding: '5px 10px', borderTop: '1px solid ' + popupBorderColor, borderLeft: '1px solid ' + popupBorderColor, borderRight: '1px solid ' + popupBorderColor,
          transform: 'translate(-50%, calc(-10px - 100%))', pointerEvents: 'none', zIndex: 1
        }}>
          <div style={{ whiteSpace: 'nowrap' }}>{regionTitle.name}</div>
          <div style={{ position: 'absolute', height: '1px', width: 'calc(50% - 6px)', top: '100%', left: '-1px', background: popupBorderColor }} />
          <div style={{ position: 'absolute', height: '1px', width: 'calc(50% - 6px)', top: '100%', right: '-1px', background: popupBorderColor }} />
          <div style={{ position: 'absolute', width: '14px', height: '10px', left: 0, right: 0, margin: '0 auto', top: '100%', overflow: 'hidden' }}>
            <div style={{
              position: 'absolute', width: '14px', height: '14px', top: '-9px', left: '0', background: popupBackground,
              borderRight: '1px solid ' + popupBorderColor, borderBottom: '1px solid ' + popupBorderColor, transform: 'rotate(45deg)'
            }} />
          </div>
        </div>
      }
      <svg
        style={{ ...style, width: '100%', height: 'auto' }}
        viewBox={`${offset.left * -1} ${offset.top * -1} ${parseFloat(mapData.attributes.width) + parseFloat(offset.left) + parseFloat(offset.right)} ${parseFloat(mapData.attributes.height) + parseFloat(offset.top) + parseFloat(offset.bottom)}`}
        {...!firsRenderState && { 'data-defaultcityposition': JSON.stringify({ x: defaultCityPosition.x * scale, y: defaultCityPosition.y * scale }) }}
        {...mapData.attributes}
        {...rest}
      >
        {mapData.regions.map((region, i) => (
          <MemoPath
            className={regionsSettings[i]?.svgMapClasses?.join(' ') || ''}
            style={regionsSettings[i]?.style || {}}
            data-list-position={i}
            fill={hoverElement === i ? (regionsSettings[i] ? regionsSettings[i].hoverColor : hoverColor) : (regionsSettings[i] ? regionsSettings[i].color : fillColor)}
            stroke={strokeColor}
            onMouseOver={() => setHoverElement(i)}
            onMouseOut={() => setHoverElement(null)}
            // eslint-disable-next-line no-sequences
            {...regionsSettings[i]?.link ? { onClick: () => (history.push(regionsSettings[i].link), $('html, body').animate({ scrollTop: 0 }, 500)) } : {}}
            key={i}
            {...region}
          />
        ))}
        {cities.map((city, i) => (
          <g
            key={city._id}
            className={city.svgMapClasses ? city.svgMapClasses.join(' ') : ''}
            transform={'translate(' + checkCoordinateValue(city.x, 'x') + ', ' + checkCoordinateValue(city.y, 'y') + ') scale(' + scale + ') translate(-' + defaultCityPosition.x + ', -' + defaultCityPosition.y + ')'}
            style={city.style || {}}
            data-list-position={i}
            // eslint-disable-next-line no-sequences
            {...city.link ? { onClick: () => (history.push(city.link), $('html, body').animate({ scrollTop: 0 }, 500)) } : {}}
          >
            <image x="0" y="0" href={Helper.getFileUrl('weather-icon', city.weather.icon + '.svg')} width="28" height="28"/>
            <rect x="30" y="4" rx="10" ry="10" width={sizes.rectWidth[city.weather.temp.length]} height="20" style={{ filter: 'drop-shadow(rgb(0, 167, 227) 1px 1px 6px)', fill: 'rgba(237, 247, 253)' }} />
            <text x={sizes.tempPosition[city.weather.temp.length] + (city.weather.temp.substring(0, 1) === '-' ? 2 : 0)} y="18" style={{ fill: '#01488f', fontSize: '13px', fontFamily: 'Roboto' }}>{city.weather.temp}</text>
            <text x={sizes.rectWidth[city.weather.temp.length] + 35} y="19" style={{ fontSize: '15px', fill: '#1f5892', fontFamily: 'Roboto', textShadow: '-2px -2px 0px #fff, 0px -2px 0px #fff, 2px -2px 0px #fff, 2px 0px 0px #fff, 2px 2px 0px #fff, 0px 2px 0px #fff, -2px 2px 0px #fff, -2px 0px 0px #fff' }}>{city.name}</text>
          </g>
        ))}
      </svg>
    </div>
  );
};

const MemoPath = React.memo((props) => <path {...props}/>, (prev, next) => !Object.keys(prev).reduce((a, v) => a || (!['onMouseOut', 'onMouseOver'].includes(v) && prev[v] !== next[v]), false));

import React, { useState, useEffect, useRef } from 'react';
import ComponentStyle from 'src/components/ComponentStyle';
import { IS_SERVER } from 'src/constants';
import $ from 'jquery';
import Helper from 'src/front/helpers/Helper';
import weatherService from 'src/services/weatherService';

function Thermometer({ temp, minTemp, maxTemp, lang }) {
  const [isShown, setIsShown] = useState(false);
  const [enableEvents, setEnableEvents] = useState(false);
  const thermometerElement = useRef(null);
  const legHeight = !IS_SERVER && Math.min((temp - minTemp) / (maxTemp - minTemp) * 100, 100);

  useEffect(() => {
    if (enableEvents) {
      const scrollHandle = () => {
        if (thermometerElement.current) {
          const element = $(thermometerElement.current);
          const elementTop = element.offset().top;
          const elementHeight = element.height();
          const scrolled = window.pageYOffset || document.documentElement.scrollTop;
      
          if (elementTop + elementHeight < scrolled + window.innerHeight && elementTop > scrolled) setIsShown(true);
          else if (elementTop + elementHeight < scrolled || elementTop > scrolled + window.innerHeight) setIsShown(false);
        }
      };
    
      window.addEventListener('scroll', scrollHandle);
      scrollHandle();
    
      return () => {
        window.removeEventListener('scroll', scrollHandle);
      };
    }
  }, [enableEvents]);

  return (
    <ComponentStyle styles={Helper.transformStyles(lang.currentLanguage, [{ src: '/css/thermometer.css' }])} Preloader={null}>
      {IS_SERVER && `{exec}dynamicValue = Math.min((${temp} - ${minTemp}) / (${maxTemp} - ${minTemp}) * 100, 100);{/exec}`}
      <div ref={(el) => (thermometerElement.current = el, true) && setEnableEvents(true)} className={`thermometer${isShown ? ' shown' : ''}`}>
        <div className="thermometer-head">
        {
          IS_SERVER ?
          `{literal}<div{if (dynamicValue == 100) :} class="red"{endif}></div>{/literal}` :
          <div {...legHeight === 100 ? { className: 'red' } : {}}/>
        }
        </div>
        <div className="thermometer-leg">
          <div/>
          {
            IS_SERVER ?
            `
              {literal}
                <div style="height: {print dynamicValue}%; background: {print dynamicValue > 50 ? '#f65e5d' : '#4A81E2'};"></div>
                <div style="bottom: {print dynamicValue}%; color: {print weatherService.getTempColor(${temp})};" class="thermometer-temp">
                  {print ${temp} > 0 ? '+' : ''}{print ${temp}}°
                </div>
              {/literal}
            `:
            <>
              <div style={{ height: `${legHeight}%`, background: legHeight > 50 ? '#f65e5d' : '#4A81E2' }}/>
              <div
                style={{ bottom: `${legHeight}%`, color: weatherService.getTempColor(temp) }}
                className="thermometer-temp"
              >
                {temp > 0 ? '+' : ''}{temp}°
              </div>
            </>
          }
        </div>
      </div>
    </ComponentStyle>
  );
}

export default Thermometer;
import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import { useHistory } from 'react-router';

if (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.mapBoxKey) {
  mapboxgl.accessToken = window.__REACT_INIT_DATA__.mapBoxKey;
  delete window.__REACT_INIT_DATA__.mapBoxKey;
}

let mapboxDefaultStyle = '';
if (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.mapboxStyle) {
  mapboxDefaultStyle = window.__REACT_INIT_DATA__.mapboxStyle;
  delete window.__REACT_INIT_DATA__.mapboxStyle;
}

// eslint-disable-next-line no-unused-vars
let lang;
let history;

const handlePopupLinkClick = (ev) => {
  let a = ev.target;
  while (a && a !== document && a.tagName.toLowerCase() !== 'a') a = a.parentNode;

  if (a && a !== document) {
    let parent = a.parentNode;
    while (parent && parent !== document && parent.className !== 'map-popup') parent = parent.parentNode;
  
    if (parent && parent !== document) {
      ev.preventDefault();
      history.push(a.getAttribute('href'));
    }
  }
};

export default ({ onInit, onLoad, lang: $lang, mapStyle, maxBounds, fitBounds, markers, interactive = true, params = {}, ...rest }) => {
  const mapRef = useRef(null);
  const metersPerPixel = 55000;
  const matersOnScreen = 15500 * 1000;
  history = useHistory();
  lang = $lang;

  useEffect(() => {
    const mapParams = {
      container: mapRef.current,
      pitch: 1,
      // See style options here: https://docs.mapbox.com/api/maps/#styles
      style:  mapStyle || mapboxDefaultStyle,
      interactive,
      minZoom: Math.log(metersPerPixel / matersOnScreen * window.innerHeight) / Math.log(2),
      //center: [15.796112852967099, 47.55870987302134],
      //zoom: 3.772530750914624
      ...params
    };

    if (maxBounds) mapParams.maxBounds = maxBounds;

    const map = new mapboxgl.Map(mapParams);

    if (fitBounds) map.fitBounds(fitBounds.reduce(
      (bounds, coord) => bounds.extend(coord),
      new mapboxgl.LngLatBounds(fitBounds[0], fitBounds[0])
    ));

    if (onInit) onInit(map);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (markers) markers = markers.map((m) => {
      const marker = new mapboxgl.Marker({ element: m.element, anchor: 'bottom' })
        .setLngLat(m.position);

      if (!m.options.minZoom || m.options.minZoom <= map.getZoom()) marker.addTo(map);

      return { marker, options: m.options };
    });

    map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    map.on('load', function () {
      if (onLoad) onLoad(map);

      map.on('zoom', function(e) {
        if (markers) {
          markers.forEach((m) => {
            if (m.options.minZoom) {
              if (m.marker._map && map.getZoom() < m.options.minZoom) {
                m.marker.remove();
              } else if (!m.marker._map && map.getZoom() >= m.options.minZoom) {
                m.marker.addTo(map);
              }
            }
          });
        }
      });

      map.on('zoomend', function(e) {
        //console.log(e);
      });

      map.on('moveend', function(e) {
        //console.log(e);
      });

      map.on('rotate', function(e) {
        //console.log(map.getBearing());
      });

      map.on('pitch', function(e) {
        //console.log(map.getPitch());
        //console.log(map.setPitch);
      });

      map.on('click', 'places', function(e) {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const description = e.features[0].properties.description;
         
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
         
        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(description)
          .addTo(map);
      });

      map.on('mouseenter', 'places', function() {
        map.getCanvas().style.cursor = 'pointer';
      });
       
      // Change it back to a pointer when it leaves.
      map.on('mouseleave', 'places', function() {
        map.getCanvas().style.cursor = '';
      });
    });

    document.addEventListener('click', handlePopupLinkClick);

    return () => {
      map.remove();
      document.removeEventListener('click', handlePopupLinkClick);
    }
  }, []);

  return <div style={{ height: '100%' }} ref={mapRef} {...rest}></div>
};
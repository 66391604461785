import React, { useState, useRef, useEffect } from 'react';
import { MONTHS, IS_SERVER } from 'src/constants';
import useLang from 'src/hooks/useLang';
import $ from 'jquery';
import Helper from 'src/front/helpers/Helper';
import useStyles from 'src/hooks/useStyles';

import styles from './style.css';

const sizes = {
  sunPositionAnimation: { width: 207, height: 103 },
  sunLight: { width: 102, height: 102 },
  sun: { width: 42, height: 42 }
};
const serverCalculations = (sunRise, sunSet, timezoneOffset, lang) => `{exec}
  dynamic_value = {
    sunRise: ${/^\{print\s+[^}]+\}/.test(sunRise) ? sunRise.replace(/^\{print\s+|\}$/g, '') : "'" + sunRise.replace(/(')/g, '\\$1') + "'"},
    sunSet: ${/^\{print\s+[^}]+\}/.test(sunSet) ? sunSet.replace(/^\{print\s+|\}$/g, '') : "'" + sunSet.replace(/(')/g, '\\$1') + "'"},
    timezoneOffset: ${/^\{print\s+[^}]+\}/.test(timezoneOffset) ? timezoneOffset.replace(/^\{print\s+|\}$/g, '') : timezoneOffset},
    date: new Date()
  };

  dynamic_value.sunRiseDate =  Helper.dateFromFormat(dynamic_value.sunRise, 'H:i').getTime();
  dynamic_value.sunSetDate = Helper.dateFromFormat(dynamic_value.sunSet, 'H:i').getTime();
  dynamic_value.sunDiff = dynamic_value.sunSetDate - dynamic_value.sunRiseDate;
  dynamic_value.sunCenterX = ${sizes.sunPositionAnimation.width} / 2;
  dynamic_value.sunCenterY = ${sizes.sunPositionAnimation.height};
  dynamic_value.sunRadius = ${sizes.sunPositionAnimation.width} / 2 - 3;

  dynamic_value.sunAngle = Date.now() < dynamic_value.sunSetDate ? (Date.now() > dynamic_value.sunRiseDate ? (Date.now() - dynamic_value.sunRiseDate) / dynamic_value.sunDiff * Math.PI : 0) : Math.PI;
  dynamic_value.sunX = dynamic_value.sunCenterX - Math.cos(dynamic_value.sunAngle) * dynamic_value.sunRadius;
  dynamic_value.sunY = dynamic_value.sunCenterY - Math.sin(dynamic_value.sunAngle) * dynamic_value.sunRadius;
  dynamic_value.side = staticData.language.direction === 'ltr' ? 'left' : 'right';

  dynamic_value.data = {
    sunLight: { side: dynamic_value.sunX - ${sizes.sunLight.width} / 2, top: dynamic_value.sunY - ${sizes.sunLight.height} / 2 },
    sun: { side: dynamic_value.sunX - ${sizes.sun.width} / 2, top: dynamic_value.sunY - ${sizes.sun.height} / 2 },
    dayLength: ${lang.t('!h h !m min', { '!h': '{print Helper.firstNumZero(Math.floor(dynamic_value.sunDiff / 1000 / 60 / 60))}', '!m': '{print Helper.firstNumZero(Math.round((dynamic_value.sunDiff % (1000 * 60 * 60)) / 1000 / 60))}' }).replace(/^\{print\s+|\}$/g, '')},
    date: dynamic_value.date.getDate(),
    month: ${lang.t({ string: '{print MONTHS[dynamic_value.date.getMonth()]}', variant: 1 }).replace(/^\{print\s+|\}$/g, '')},
  };
{/exec}`;

function SunPosition({ sunRise, sunSet, timezoneOffset }) {
  const lang = useRef(null);
  lang.current = useLang('SunPosition');

	let [data, setData] = useState(null);
	const element = useRef(null);
	const timer = useRef(null);
	const side = !IS_SERVER && lang.current.currentLanguage.direction == 'ltr' ? 'left' : 'right';
	const date = Helper.getLocationDate(timezoneOffset);
  const firstRender = useRef(true);

  useStyles(styles);

  const createData = (toReturn = false) => {
    const sunRiseDate =  Helper.dateFromFormat(sunRise, 'H:i').getTime();
    const sunSetDate = Helper.dateFromFormat(sunSet, 'H:i').getTime();
    const sunDiff = sunSetDate - sunRiseDate;
    const sunCenterX = sizes.sunPositionAnimation.width / 2;
    const sunCenterY = sizes.sunPositionAnimation.height;
    const sunRadius = sizes.sunPositionAnimation.width / 2 - 3;

    const sunAngle = Date.now() < sunSetDate ? (Date.now() > sunRiseDate ? (Date.now() - sunRiseDate) / sunDiff * Math.PI : 0) : Math.PI;
    const sunX = sunCenterX - Math.cos(sunAngle) * sunRadius;
    const sunY = sunCenterY - Math.sin(sunAngle) * sunRadius;

    const date = new Date();

    if (!toReturn) timer.current = null;

    const response = {
      sunLight: { display: 'block', [side]: sunX - sizes.sunLight.width / 2, top: sunY - sizes.sunLight.height / 2 },
      sun: { display: 'block', [side]: sunX - sizes.sun.width / 2, top: sunY - sizes.sun.height / 2 },
      dayLength: lang.current.loaded && lang.current.t('!h h !m min', { '!h': Helper.firstNumZero(Math.floor(sunDiff / 1000 / 60 / 60)), '!m': Helper.firstNumZero(Math.round((sunDiff % (1000 * 60 * 60)) / 1000 / 60)) }),
      date: date.getDate(),
      month: lang.current.loaded && lang.current.t({ string: MONTHS[date.getMonth()], variant: 1 }),
    };

    if (toReturn) return response;
    else setData(response);
  };

	if (!IS_SERVER && !timer.current) timer.current = setTimeout(createData, 1000);


  if (IS_SERVER) {
    data = {
      sunLight: { display: 'block', '{print dynamic_value.side}': '{print dynamic_value.data.sunLight.side}px', top: '{print dynamic_value.data.sunLight.top}px' },
      sun: { display: 'block', '{print dynamic_value.side}': '{print dynamic_value.data.sun.side}px', top: '{print dynamic_value.data.sun.top}px' },
      dayLength: '{print dynamic_value.data.dayLength}',
      date: '{print dynamic_value.data.date}',
      month: '{print dynamic_value.data.month}',
    };
  } else if (!data) data = createData(true);

  useEffect(() => () => clearTimeout(timer.current), []);

  useEffect(() => {
    if (firstRender.current) firstRender.current = false;
    else {
      clearTimeout(timer.current);
      createData();
    }
  }, [sunRise, sunSet, timezoneOffset]);

	return (
    <>
      {IS_SERVER && serverCalculations(sunRise, sunSet, timezoneOffset, lang.current)}
  		<div className="sun-position" ref={element}>
        <div className="sun-position-animation">
          <img alt="Weather" width="284" height="284" loading="lazy" style={data.sun} src={Helper.getFileUrl('weather-icon', 'd000.svg')}/>
          <div className="sun-position-animation-icon-light" style={data.sunLight}></div>
          <div className="sun-position-animation-date">{data.date}</div>
          {
            data.month ?
            <div className="sun-position-animation-month">{data.month}</div> :
            <div className="sun-position-animation-month" dangerouslySetInnerHTML={{ __html: '&nbsp;' }}/>
          }
          {
            lang.current.loaded || IS_SERVER ?
            <div className="sun-position-animation-title">{lang.current.t('Daylight hours')}</div> :
            <div className="sun-position-animation-title" dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
          }
        </div>
        <div className="sun-position-rise-set clear">
          <div className="sun-position-rise">{sunRise}</div>
          <div className="sun-position-set">{sunSet}</div>
          {
            data.dayLength ?
            <div className="sun-position-rise-day-length">{data.dayLength}</div> :
            <div className="sun-position-rise-day-length" dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
          }
        </div>
      </div>
    </>
	);
}

export default SunPosition;
import React, { useState, useContext, useEffect } from 'react';
import { FrontContext } from '../helpers/FrontContext';
import useLang from 'src/hooks/useLang';
import { API_URI, DAYS, MONTHS, IS_SERVER } from 'src/constants';
import Helper from '../helpers/Helper';
import axios from 'axios';
import Link from 'src/front/components/Link';

let _data = null;

export function useInfoBlockData() {
  const { myCities } = useContext(FrontContext);
  const [data, setData] = useState(
    !IS_SERVER ?
    (
      window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.infoBlocks ?
      (_data = { ...window.__REACT_INIT_DATA__.infoBlocks, weatherLastUpdate: Helper.dateFromFormat(window.__REACT_INIT_DATA__.infoBlocks.weatherLastUpdate, 'd/m/Y, H:i:s') }) :
      _data
    ) :
    null
  );
  if (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.infoBlocks) delete window.__REACT_INIT_DATA__.infoBlocks;

  useEffect(() => {
    if (myCities[0]?.timeZone && (!data || myCities[0].timeZone !== data.timeZone)) {
      axios
        .get(`${API_URI}/front/info-block?timeZone=${encodeURIComponent(myCities[0].timeZone)}`, { withCredentials: true })
        .then(({ data }) => setData(_data = { ...data, weatherLastUpdate: Helper.dateFromFormat(data.weatherLastUpdate, 'd/m/Y, H:i:s') }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myCities[0]?.timeZone]);

  return data;
}

function InfoBlocks() {
  const lang = useLang('InfoBlocks');
  const data = useInfoBlockData();

  if (!IS_SERVER && !data) return null;

  return (
    <div className="o-hidden">
      <ul className="page-info-list fs-0">
        <li className="va-top">
          <div className="page-info-list-title">{lang.t('Updated every !m minutes', {'!m': 30})}</div>
          <div className="page-info-list-value">
            <div className="fw-500">
              {IS_SERVER && "{const weatherLastUpdate = Helper.dateFromFormat(infoBlocks.weatherLastUpdate, 'd/m/Y, H:i:s')}"}
              <span>{IS_SERVER ? lang.t('{print DAYS[weatherLastUpdate.getDay()]}') : lang.t(DAYS[data.weatherLastUpdate.getDay()])}</span>
              <span>, </span>
              <span>{IS_SERVER ? "{print Helper.date('H:i', weatherLastUpdate.getTime())}" : Helper.date('H:i', data.weatherLastUpdate.getTime())}</span>
            </div>
            <span>{IS_SERVER ? '{print weatherLastUpdate.getDate()}' : data.weatherLastUpdate.getDate()}</span> <span>{lang.t({ string: IS_SERVER ? '{print MONTHS[weatherLastUpdate.getMonth()]}' : MONTHS[data.weatherLastUpdate.getMonth()], variant: 1 })}</span> <span>{IS_SERVER ? '{print weatherLastUpdate.getFullYear()}' : data.weatherLastUpdate.getFullYear()}</span>
          </div>
        </li>
        <li className="va-top">
          <div className="page-info-list-title">{lang.t('At your disposal')}</div>
          <div className="page-info-list-value fw-500">
            <div>{lang.t('<span key="1" className="inline-block tempr">!c</span> cities', {'!c': IS_SERVER ? "{print Helper.numberFormat(infoBlocks.citiesQuantity, 0, '', ' ')}" : Helper.numberFormat(data.citiesQuantity, 0, '', ' ')}, { replaceClassName: true, removeKeyAttr: true })}</div>
            {
              (IS_SERVER || lang.isLink('/country')) &&
              <>
                {IS_SERVER && `{if (${lang.isLink('/country')}) :}`}
                <span>{lang.t('!c countries in the world', {'!c': IS_SERVER ? '{print infoBlocks.countriesQuantity}' : data.countriesQuantity})}</span>
                {IS_SERVER && '{endif}'}
              </>
            }
            {
              (IS_SERVER || !lang.isLink('/country')) &&
              <>
                {IS_SERVER && `{if (!(${lang.isLink('/country')})) :}`}
                <Link to={lang.l('/country')}>{lang.t('!c countries in the world', {'!c': IS_SERVER ? '{print infoBlocks.countriesQuantity}' : data.countriesQuantity})}</Link>
                {IS_SERVER && '{endif}'}
              </>
            }
          </div>
        </li>
        <li className="va-top">
          <div className="page-info-list-title">{lang.t('Provided information')}</div>
          <div className="page-info-list-value fw-500">
            <span>{lang.t('Weather change and weather forecast for 10 days')}</span>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default InfoBlocks;

import React, { useContext } from 'react';
import { IS_SERVER } from 'src/constants';
import Link from 'src/front/components/Link';
import useLang from 'src/hooks/useLang';
import DimensionUnitsConvertor from 'src/utils/DimensionUnitsConvertor';
import weatherService from 'src/services/weatherService';
import { FrontContext } from 'src/front/helpers/FrontContext';
import Helper from 'src/front/helpers/Helper';
import useStyles from 'src/hooks/useStyles';

import styles from './style.css';

function CitiesList({ cities, countryLink, maxCities, maxCitiesLink }) {
	const lang = useLang('CitiesList');
	const { dimensionUnits } = useContext(FrontContext);

	useStyles(styles);

	return (
		<ul className="component-cities-list">
      {(IS_SERVER ? [''] : cities).map((c, n) => <>
          {IS_SERVER && `{for (let i = 0; i < ${cities}.length; i++) :}`}
          	{
          		(IS_SERVER || !Helper.isNumeric(maxCities) || n < maxCities) &&
          		<>
          			{IS_SERVER && `{if (!Helper.isNumeric(${maxCities}) || i < ${maxCities}) :}`}
		            <li key={n}>
		              <Link to={lang.l(IS_SERVER ? `{print ${countryLink} + ${cities}[i].link}` : `${countryLink}${c.link}`)}>
		                <div className="country-region-city-name">
		                  <div className="text-8 color-2">{IS_SERVER ? `{print Helper.getFieldValue(${cities}[i].name, staticData.language._id)}` : Helper.getFieldValue(c.name, lang.currentLanguage._id)}</div>
		                  <div><span className="text-10 color-13">{lang.t('At night')}: </span><div className="tempr inline-block text-12 color-1">{IS_SERVER ? `{print DimensionUnitsConvertor.temperature(${cities}[i].weather.nightTemp, dimensionUnits.temperature)}` : DimensionUnitsConvertor.temperature(c.weather.nightTemp, dimensionUnits.temperature)}</div></div>
		                  <div><span className="text-10 color-13">{lang.t('Tomorrow')}: </span><div className="tempr inline-block text-12 color-1">{IS_SERVER ? `{print DimensionUnitsConvertor.temperature(${cities}[i].weather.tomorrowTemp, dimensionUnits.temperature)}` : DimensionUnitsConvertor.temperature(c.weather.tomorrowTemp, dimensionUnits.temperature)}</div></div>
		                </div>
		                <div className="country-region-city-tempr text-14" style={{ color: IS_SERVER ? `{print weatherService.getTempColor(${cities}[i].weather.temp)}` :  weatherService.getTempColor(c.weather.temp) }}>
		                  {IS_SERVER ? `{print DimensionUnitsConvertor.temperature(${cities}[i].weather.temp, dimensionUnits.temperature)}` : DimensionUnitsConvertor.temperature(c.weather.temp, dimensionUnits.temperature)}
		                </div>
		                <img width="284" height="284" alt="Weather" src={IS_SERVER ? `{print Helper.getFileUrl('weather-icon', ${cities}[i].weather.icon + '.svg')}` : Helper.getFileUrl('weather-icon', `${c.weather.icon}.svg`)} loading="lazy"/>
		              </Link>
		            </li>
		            {IS_SERVER && '{endif}'}
	            </>
	          }
	          {
	          	(maxCitiesLink && (IS_SERVER || (Helper.isNumeric(maxCities) && n === maxCities))) &&
	          	<>
                {IS_SERVER && `{if (i === ${maxCities}) :}`}
                <li className="max-cities-link" key="max-cities-link">
                  <Link to={lang.l(maxCitiesLink.link)}>
                    <span className="inline-block va-middle text-6">{maxCitiesLink.text}</span>
                  </Link>
                </li>
                {IS_SERVER && '{endif}'}
              </>
	          }
          {IS_SERVER && '{endfor}'}
        </>
      )}
    </ul>
	);
}

export default CitiesList;
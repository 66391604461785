import React from 'react';
import useLang from 'src/hooks/useLang';
import Link from 'src/front/components/Link';
import useHandlePopup from '../helpers/FrontContext';
import { IS_SERVER } from 'src/constants';
import $ from 'jquery';

function PageInfoButtons({ buttons = ['main', 'my cities', 'feedback'] }) {
  const lang = useLang('PageInfoButtons');
  const handleOpenPopup = useHandlePopup();

  const openMyCitiesPopup = (event) => {
    handleOpenPopup({
      event,
      element: $('#my-cities-popup > div'),
      name: 'my-cities-popup',
      closeCheck: (ev) => ev.target.id === 'my-cities-popup',
    });
  };

  return (
    <ul className="page-info-buttons">
      {
        buttons.includes('favorites') &&
        <li className="inline-block va-middle">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="page-info-buttons-favorite" href="#">{lang.t('Add page to favorites')}</a>
        </li>
      }
      {
        buttons.includes('main') &&
        <li className="inline-block va-middle">
          {
            (IS_SERVER || !lang.isLink('/')) &&
            <>
              {IS_SERVER && `{if (!(${lang.isLink('/')})) :}`}
              <Link className="page-info-buttons-on-main" to={lang.l('/')}>{lang.t('On main')}</Link>
              {IS_SERVER && '{endif}'}
            </>
          }
        </li>
      }
      {
        buttons.includes('my cities') &&
        <li className="inline-block va-middle">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={openMyCitiesPopup} className="page-info-buttons-my-cities" href="#">{lang.t('My cities')}</a>
        </li>
      }
      {
        buttons.includes('feedback') &&
        <li className="inline-block va-middle">
          {
            (IS_SERVER || !lang.isLink('/feedback/')) &&
            <>
              {IS_SERVER && `{if (!(${lang.isLink('/feedback/')})) :}`}
              <Link className="page-info-buttons-write-us" to={lang.l('/feedback/')}>{lang.t('Write us')}</Link>
              {IS_SERVER && '{endif}'}
            </>
          }
        </li>
      }
    </ul>
  );
}

export default PageInfoButtons;
